import React, { useEffect } from "react";
import Navbar from "../components/Navbar"; // Import Navbar

const Conversion = () => {
  useEffect(() => {
    // Check if gtag function is available
    if (typeof window.gtag === "function") {
      // Trigger the Google Ads conversion event
      window.gtag('event', 'conversion', {
        'send_to': 'AW-16542485881/DX4rCJKGydYZEPmaidA9',
        'transaction_id': ''
      });
    } else {
      console.warn("Google Tag Manager (gtag) not loaded yet.");
    }
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <div className="flex flex-col items-center justify-center px-4 py-16 md:py-24">
        {/* Thank You Message */}
        <h1 className="text-3xl md:text-5xl font-bold text-center mb-8">
          Thank You for Your Purchase!
        </h1>

        {/* Celebratory Animation */}
        <div className="text-2xl md:text-3xl mb-12 animate-bounce">
          🎉 Congratulations on joining our Bootcamp! 🎉
        </div>

        {/* Instructions Section */}
        <div className="w-full max-w-3xl space-y-8">
          {/* Instruction 1 */}
          <div className="bg-gray-800 rounded-lg shadow-lg p-6 md:p-8 flex items-center">
            <div className="flex-shrink-0 bg-[#00df9a] text-black rounded-full h-12 w-12 flex items-center justify-center font-bold text-xl mr-4">
              1
            </div>
            <p className="text-lg md:text-xl">
              <a
                href="https://discord.gg/fnqWGwakR9"
                className="text-[#00df9a] hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Join our Discord Server
              </a>
            </p>
          </div>

          {/* Instruction 2 */}
          <div className="bg-gray-800 rounded-lg shadow-lg p-6 md:p-8 flex items-center">
            <div className="flex-shrink-0 bg-[#00df9a] text-black rounded-full h-12 w-12 flex items-center justify-center font-bold text-xl mr-4">
              2
            </div>
            <p className="text-lg md:text-xl">
              Check your email inbox linked with your PayPal account.
            </p>
          </div>

          {/* Instruction 3 */}
          <div className="bg-gray-800 rounded-lg shadow-lg p-6 md:p-8 flex items-center">
            <div className="flex-shrink-0 bg-[#00df9a] text-black rounded-full h-12 w-12 flex items-center justify-center font-bold text-xl mr-4">
              3
            </div>
            <p className="text-lg md:text-xl">
              Follow the instructions to <strong>access premium Discord Bootcamp channels!</strong>
            </p>
          </div>
        </div>

        {/* Additional Information */}
        <div className="mt-12 text-center text-sm text-gray-400">
          <p>
            If you encounter any issues, please contact our support team on{" "}
            <a
              href="https://discord.gg/fnqWGwakR9"
              className="text-[#00df9a] hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Discord
            </a>
            .
          </p>
          <p className="mt-2">
            The confirmation email should arrive within a few minutes. Please check your spam folder if you don't see it.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Conversion;
