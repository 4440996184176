import React, { useState } from "react";
import { FaStar } from "react-icons/fa"; // Ensure you have react-icons installed
import user1 from "../assets/user1.webp";
import user2 from "../assets/user2.webp";
import user3 from "../assets/user3.webp";
import user4 from "../assets/user4.webp";
import user5 from "../assets/user5.webp";
import arlequin from "../assets/arlequin.webp";
import defaultAvatar from "../assets/defaultAvatar.png"; // Import default avatar image

const Review = () => {
  const starRating = (rating) => {
    return (
      <div className="flex">
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <FaStar
              key={index}
              className={`h-4 w-4 ${
                index < rating ? "text-yellow-400" : "text-gray-600"
              }`}
            />
          ))}
      </div>
    );
  };

  // Your list of reviews (include all your reviews here)
   const allReviews = [
    {
      name: "utemax",
      avatar: user1,
      review:
        "It's scary how with just one class, in a matter of weeks I managed to go from Emerald 4 to Diamond 4, with these lessons and a little consistency in games it's possible to climb easily friends, trust the process.",
      rating: 5,
    },
    {
      name: "arlequin",
      avatar: arlequin,
      review:
        "Pretty straightforward feedback and analysis, I learnt things that I thought were totally irrelevant, that were in fact super important, about my gameplay, my decisions and more. 5/5",
      rating: 5,
    },
    {
        name: "legendarynub1",
        rating: 4.7,
        review: "I was going to vouch once I reached challenger, but i found myself to be busy soon so my time is going to be less and less to grind for league, so figured I would vouch now\n\nI struggled using my TP consistently with trundle and itemization, as well as some of trundle's skills previously [and sagittarius taught me how to use my ult properly], as well as proper itemization [A GOOD bit of itemization, which I was not knowledgeable about]\n\nAfter taking 2 sessions, Sagittarius really helped me to be a VERY Consistent player, which helped a lot in making my play stable and making me a better player [it can't help with shitty teammates, ofc, but i was a way better player than before the coaching].\nI also got coaching 2 years ago from him, which made me jump from d2 to masters [Albeit at the time, I was not one tricking trundle specifically, and he smoothened my macro]. It is very nice to have a coach like sagittarius to help me with the game. Coach sagi really helped with making me a better player in general, and it shows in my KDA [whereas previously, from what i recall 2 years ago when i tried trundle in masters, It was hard to play]. Sagittarius taught me how to be more confident, as well as which areas I am lacking on [like jungle tracking, and dying for being greedy].\n\nI have decided to lessen my league time, but I will definitely be looking forward to the rest of the coaching sessions that I have booked with coach sagi. Would recommend 10/10, as coach sagi really polishes you into a better player in general",
        avatar: null
    },
    {
      name: "Cogito Ergo Sum",
      avatar: user2,
      review:
        "Found this channel randomly and never felt so lucky, the lesson from Sagittarius was eye opening, glad to be part of this community!",
      rating: 5,
    },
    {
      name: "BunIzzet",
      avatar: user3,
      review:
        "Your ward bootcamp REALLY helped!!! I've been getting so many more objectives and avoiding ganks, and as Viktor it's good to be aware of ganks!",
      rating: 5,
    },
    {
      name: "Kaboss",
      avatar: user4,
      review:
        "You've heard of fast-acting cold medicine. Have you heard of fast-acting League coaching? (from E3 to D4 50LP)",
      rating: 5,
    },
    {
      name: "Ainz Ooal Gown",
      avatar: user5,
      review:
        "So I've played League since season 7 and I've never seen diamond but all my friends were always dissing me 'haha you can't get diamond'. Well in season 13 because of Sagittarius I have finally done it. Thank you so much. Now...time for masters.",
      rating: 5,
    },
    {
        name: "shikuremina",
        rating: 5,
        review: "Sagi gave me practical tips I could use right away. He broke down strategies, helped me sharpen my mechanics, and guided me to make smarter decisions during matches. The session was engaging, well-organized, and perfectly suited to my level of play. Highly recommend for anyone looking to elevate their game!",
        avatar: null
    },
    {
        name: "vitorwindberg",
        rating: 5,
        review: "Really good coach, I got to see things in my game and mistakes that I could not find by myself. At the end of the session, after going through everything, he sent me a message with everything I needed to work on to achieve my next goal which made it easier for me to follow later on.",
        avatar: null
    },
    {
        name: "fabianengineer",
        rating: 5,
        review: "Sagi understands the game in depth. The coaching is very professional, easy to understand and you have the feeling that you can really achieve something with it. This is also evident shortly afterwards in the games. He takes the time to listen to you and answer all your questions. Really great! Thank you very much ",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/c8feb7769583a55bfea36c99866df402-1651218390028/5b5d27d5-77a4-4809-9259-75dad8e600c3.jpg"
    },
    {
        name: "luminous_labs",
        rating: 5,
        review: "Sagi truly knows his stuff. He has done a great job of correcting my mistakes and also giving me perspective on what a high-elo player would do to impact the map. I highly recommend this to any league player but especially ADCs.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/63da4255d2b3cfc7147a860cadd87e02-1614205408686/fb4c9a13-e746-443a-b1aa-959f9656d9c9.png"
    },
    {
        name: "devranow",
        rating: 5,
        review: "Was a great coaching and I'm gonna buy another session once I have learned to apply what I learned in the first one. Sagi is super proactive and a great coach, would recommend him to anyone who is genuinely trying to improve.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/0642e63b77eb84556f9bd20fdf98f5e0-1712887646451/65aab5d0-8d6b-4a9e-973c-b3218c6a722d.jpg"
    },
    {
        name: "omri_10",
        rating: 5,
        review: "Absolute pleasure to work with. Communicative and professional. Thank you!",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/e8eb2134395ddc37a6712d165689c7b7-1712647052339/433e5ec7-67fc-4425-97af-249064a2a97e.png"
    },
    {
        name: "izcrezy",
        rating: 5,
        review: "I could immediately tell that Sag knew what he was talking about. He has a very good game sense and much better than the previous coaches I've used before. He is polite, realistic and his fees are very reasonable. Really happy I started with him as my first coach, I would recommend him to anyone",
        avatar: null
    },
    {
        name: "lightprince01",
        rating: 5,
        review: "Once again, really informative session, feel like there was a lot I could take from it. Even after the session is done, I can rewatch it and try make sure I continue to progress and improve to the rank I have aimed for. :)",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/46c5b50b62f7aeb16f5c7a6a7527f52f-1680914376357/84547d52-4b13-4115-a195-7447c8d549ae.png"
    },
    {
        name: "luisalb9618",
        rating: 5,
        review: "Amazing coach. Really pointed out everything in specific details",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/53edb5095d6e29f8aef19ea510f22b52-1101533671685815490.693507/299F9F23-8F96-4E19-9232-037BF5846A86"
    },
    {
        name: "lightprince01",
        rating: 5,
        review: "Really informative, helped me learn alot about things that I was doing wrong that other coaches wouldn't pick up on. Will definitely come back again :)",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/46c5b50b62f7aeb16f5c7a6a7527f52f-1680914376357/84547d52-4b13-4115-a195-7447c8d549ae.png"
    },
    {
        name: "johnnyplease",
        rating: 5,
        review: "Ok this was definitely one of the best coaching sessions I’ve had. I’m. Coming. Back.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/0692172795f30288afc695db7e2bdcaf-1693524773054/d1995be6-a81c-497b-8d08-f6167cee1fdf.jpeg"
    },
    {
        name: "shadow2y",
        rating: 5,
        review: "My friend group and I have been working alongside Sagittarius for a total of 5 coaching sessions so far. Our needs are vastly different from each other as we each play a different role in League of Legends and have varying degrees of experience ranging from complete new players to platinum. \nWhile there is a certain process and workflow in place (which makes the coaching sessions smooth and easy to follow), each session was adapted to the player's experience and area of improvement. Saggitarius is quick to pinpoint the pain points and low-hanging fruit for each individual and swiftly creates tailor-made coaching that yields almost immediate results: For example, our gold player reached platinum only one week later after applying the guidance he received. \n\nI would also like to highlight that the team was allowed to observe the other coaching sessions from our stack which is great money for value. Last but not least, Sagittarius is a great communicator both during and outside the coaching sessions. While his Discord responses will not be immediate due to his busy schedule, he will always follow up with you at a later time.\n\nNeedless to say, we are planning to book additional sessions with him in the future.",
        avatar: null
    },
    {
        name: "inno_winston",
        rating: 5,
        review: "This went way beyond expectations. Easily the best coach money can buy. The fact his prices are so affordable is criminal. I used to be a semi-pro in fighting games, and I've competed/socialized with complete frauds and the best of the best, so I know when someone just likes to hear the sound of their own voice, and Sagittarius is the furthest from that. Absolutely solid information and if you ask the right questions, you will get SO much out of the session. Service itself was spectacular; he really does go out of his way to make sure you're taken care of. Will be purchasing again after some alone time in the lab using the tips and recommendations he gave. Also a fun guy to converse with. 10/10 recommend. ",
        avatar: null
    },
    {
        name: "allenlin125",
        rating: 5,
        review: "Ordered another coaching session and opened my pov to many mistakes that puts the different between a master player and a GM player",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/019f56ab5a5ef0cf028c11cc8ada5c9d-1521519341685017889.233068/3CCBC069-291D-427E-91D1-FEBB8B4B1281"
    },
    {
        name: "cessless",
        rating: 5,
        review: "Very impressive knowledge, and very patient. Would highly recommend.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/482780dd87d6791241e2380f87707ada-727842241719105746.784827/51D9B344-AF56-4CB2-8E31-A6BD976F80AC"
    },
    {
        name: "nlvzapteh",
        rating: 5,
        review: "good coach :)",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/3b9c034e96f5337561990cbca17b3112-1574813300138/14726abb-f286-47e6-ba66-8366057e3ce1.png"
    },
    {
        name: "abeslayer666",
        rating: 5,
        review: "Great experience overall very helpfull",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/3c6983a8671e6b0b58331f6ba92aec4d-1536419003902/a3cdf316-706a-4ee3-87fe-9784d7975297.jpg"
    },
    {
        name: "joseamica7",
        rating: 5,
        review: "amazing, very recommended",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/6d30606f161398450a2fa0c20e6d91ca-1633519051581/67cfd8dd-0a2b-4de5-93ff-06e3bfff3fa1.jpg"
    },
    {
        name: "shl0gans0n",
        rating: 5,
        review: "Sagittarius was awesome to work with. He was flexible with coaching me, even though I played the mobile version of League. During the coaching session, he pinpointed key issues in my gameplay and provided me with further examples as the game progressed. Would recommend his services to others. Jungle main",
        avatar: null
    },
    {
        name: "dandavila",
        rating: 5,
        review: "He was very insightful and provided helpful feedback. Would highly recommend if wanting to seriously climb the ranks of league. :)",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/b5e1b7c7344ea8fc79a0a4441eb118cc-1561196628938/982d705a-02ce-493e-9844-53956aa4264c.jpg"
    },
    {
        name: "sazkir",
        rating: 5,
        review: "Very easy to communicate with, Sagittariusadc, has both the knowledge a good coach should have but the people skills to implement it in a productive environment,  I feel like with continued work with them Anyone could get really good at the game. \n\nIf you are like me an older player coming back with bad habits and not sure where to start learning all the new parts of league there is no better option, I am looking forward to future coaching",
        avatar: null
    },
    {
        name: "izaxx72",
        rating: 5,
        review: "After my first Session and understanding the Fundamentals, I realize how much my gameplay improves, even if my current ELO is really low, my gameplay improves by a significant amount after understanding what spacing and wave management is for a mid laner. Instead of having a high death rate every game, I can see that my death ratio goes down by a lot. I can really recommend Sagi as a coach, because he is not only telling you what you're doing wrong, he takes actively care to remove all of those bad haAfter my first lesson and learning the basics, I see a big improvement in how I play games. Even though my ranking is still low, I play much bits in your gameplay and fix them together with you.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/f8be06000b273c6e8a954371e0422761-1706493432738/5397b551-202c-49c0-b24e-b822b35d5ce5.jpg"
    },
    {
        name: "lunalafoxpanda",
        rating: 5,
        review: "I'm a returning former high elo player, Sagi helped me figure out my playstyle and champion pool, gave me notes on what to improve on, helped me learn concepts that otherwise eluded me. I climbed from E3 to D3 within 2 days of the sesssion. I have a follow up session as well. He also taught me itemization and hot to spot/set up windows to all in. The most important thing was he taught/gave me confidence and told me  to trust myself. \nIf you're a new/returning player and want to improve I HIGHLY recommend Sagi.",
        avatar: null
    },
    {
        name: "steeple_",
        rating: 5,
        review: "Great understanding of the game.  Helped me build confidence in my gameplay.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/886f60196f3ff66bd7162e0015a39794-1698348949387/b92336e7-fe78-41f7-a3a5-386da537d4f0.jpg"
    },
    {
        name: "fabianluft",
        rating: 5,
        review: "Very good coach .\nWas very good experience \nAlways again ",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/ddefa96311f32eafc5046a64baf93cd5-952680601601344852.852123/EF8A8D79-3F01-4E0C-BCA4-B089712120F8"
    },
    {
        name: "nikpanda88",
        rating: 5,
        review: "He's awesome. he shows you how to improve your mechanics and he shows you how to fix your laning mistakes. I highly recommend him. Even if you are picking a new champion for the first time, Saj has you covered. He shows you combos in practise tool where needed. For example, he does cover Samira really really well. Champs are not a problem for him. He's legit good!",
        avatar: null
    },
    {
        name: "mrflimflampkmn",
        rating: 5,
        review: "After being hardstuck Bronze for over a year I bought this coaching session. The very next day I am looking at my screen and I am Silver 4 for the first time. As a Volibear player I had access to tips and advise from one of the best Volibear players, which in my opinion is invaluable!",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/cd1a7d58cb1c15cda2a30986b7801fb7-922044101661798338.8718991/D5F66841-6D4E-4A65-B0AA-A9F462A62D1C"
    },
    {
        name: "glen_the_glider",
        rating: 5,
        review: "He is really good at finding repeated mistakes that are easy to remove/improve on to make your games easier. No matter what skillevel you are he can always help you in some way. Coaching structure was good, smooth and pleasant. If you are trying to improve or want coaching i would highly recommend Sagi. :)",
        avatar: null
    },
    {
        name: "gabemarty",
        rating: 5,
        review: "Fantastic coach, so many thing I now see many things that I was doing wrong and how I can improve to be a true Top Lane Destroying Beyblade. Thanks Saggitariusadc I will definitely be returning for more sessions with you!",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/003578299f86dd16af561cc10a26c30c-1689610123351/4ffbf8ad-e98f-4171-8837-cd714da567eb.png"
    },
    {
        name: "alwayzsleepyy",
        rating: 5,
        review: "VERY GOOD GET ME NO MORE HARDSTUCK",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/c54585ec93483f365a4e1d12335c581d-1719234386692/eb630025-cf71-4ab0-b46b-ae95dee6d930.jpg"
    },
    {
        name: "nikpanda88",
        rating: 5,
        review: "Saj always finds out what's going wrong with your play style. He showed me many instances where I was making the same mistake. I consistently get leads, but I don't know how to use them effectively. He showed me where I was going wrong at multiple checkpoints. \n\nHe also reminded me of something I used to do in S11, which I completely forgot about over the last 2 years... \nAnother thing I love about him is how he doesn't tell you to play XYZ champ. You play what you want, he will show you where you are going wrong with said champ or your own playstyle. He will tell you, your champ is weak. But he will also show you how you can make the most of it if that is what you want. He's super!",
        avatar: null
    },
    {
        name: "josahty",
        rating: 5,
        review: "Sagittarius is awesome! From our very first call I noticed improvements right away. He pointed out things to me I hadn't even thought of in over 5 years of playing League. Will definitely be hitting a new high rank this season!",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/3ae4cc0355e6ef8346b73fd0a713dbb2-1573053441119/179fdae7-71f3-42fe-8c4e-82057626451f.JPG"
    },
    {
        name: "ignivulp",
        rating: 4.7,
        review: "Quick Delivery and very detailed, took his time explaining what I did wrong, and how to improve, once I fixed my mistakes I saw the improvement. \nSagittarius Helped me with my Micro, by giving me drills to practice and pointed out my Macro mistakes which cost me my games. 10/10 \nreached plat thanks to this coach.",
        avatar: null
    },
    {
        name: "wrightsreptiles",
        rating: 5,
        review: "A++++++ Great Coach",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/16eb4c9ab55addc38a945f0745c39acc-934968121690728199076/JPEG_20230730_104317_5714295620526658220.jpg"
    },
    {
        name: "capital_finance",
        rating: 5,
        review: "Best coach in the world changed my view on the whole game. Very extensive system with Excel sheets and personal evaluation. Massive amounts of resources given. Feedback is supported by extensive experience of coaching and game knowledge. Extremely friendly but straight to the point person. Worth every penny. 10/10\n",
        avatar: null
    },
    {
        name: "allenlin125",
        rating: 5,
        review: "Extremely helpful and flexible. He was giving great advice and tips on how to improve not only mechanics also wave control, when to efficiently trade, meta picks, and talk about proper spacing and windows of opportunity. Spend the extra 5-10 dollars on him for quality coaching. Quality>Quantiy",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/019f56ab5a5ef0cf028c11cc8ada5c9d-1521519341685017889.233068/3CCBC069-291D-427E-91D1-FEBB8B4B1281"
    },
    {
        name: "guspbezerra",
        rating: 5,
        review: "Lovely experience ",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/26dbddb8d75a11facddecce52fd53cc7-1626787594853/f8afcf07-e398-42dc-b200-932290c4cc31.png"
    },
    {
        name: "minnthiha00",
        rating: 5,
        review: "I bought this coaching session for a friend and he really liked it! He learned how to focus on his fundamentals and he's motivated to try out the things that he learned. I'm personally going to buy some more coaching sessions for myself in the future. Most likely the monthly one!",
        avatar: null
    },
    {
        name: "petarpesic342",
        rating: 5,
        review: "A really good coach I would recommend him for learning the ADC role.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/86d05480ff7de6e81063f31918b01d46-1682981565911/ed0a399b-290a-4a38-8245-ae6654961e8d.png"
    },
    {
        name: "masonkoppen6550",
        rating: 5,
        review: "Great coach and very detailed. ",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/18af867d67dbeabe9abc88b035d34fa6-1370946451685144115.711/75F9E76D-EF59-4E01-BB22-8C2BE2BDFA29"
    },
    {
        name: "xidan_",
        rating: 5,
        review: "The coach was very chill and patient, he clearly has a lot of experience as his coaching was very organized. Was able to point out my mistakes pretty quickly and provided valid resources and exercises to fix those mistakes. Most likely would order another session once I feel I've hit a wall again.",
        avatar: null
    },
    {
        name: "elgilouuu",
        rating: 5,
        review: "Very interesting and very helpful session, would recommend 100% and will do it again asap!! ",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/e0da8e7b947c243052e7f16ff6279f24-1215150361644673555.5331979/76D3F9A5-361B-42B2-964C-75DF536D0B5C"
    },
    {
        name: "adwando",
        rating: 5,
        review: "I had a coaching session a few weeks ago that proved to be immensely helpful. Since then, I've managed to climb several divisions and my win rate has improved significantly. The coach focused on fundamentals that I hadn't realized were so crucial. Throughout the session, he took notes which he shared with me at the end, along with additional resources for further development. While he explained things very slowly, which meant we spent a lot of time on certain points, it did ensure that I fully grasped the concepts he was teaching. Overall, I highly recommend this coach for his extensive knowledge and expertise.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/846c40d7a8e538a0eecd426083dddffe-1574520754652/c3414898-6a65-4fff-91d4-d0a5f4152803.png"
    },
    {
        name: "mariailie",
        rating: 5,
        review: "Really great coach, did his homework about what my specific needs were and put together a fantastic plan for improvements.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/d03813baee79c38e39a9bc19dcaf9824-1683089882580/4c7d740f-a89c-4a6b-b580-799a8e31ca89.jpg"
    },
    {
        name: "allanschiller77",
        rating: 5,
        review: "What an amazing service if you are like me, loving the LOL, but having some issues with your game performance, the seller here really know how to pinpoint your mistake (that you might not know you are doing) and helps you correct them. Will defiantly learn more from this guy",
        avatar: null
    },
    {
        name: "mellononce",
        rating: 5,
        review: "I'm really happy with the coaching, planning to get another one.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/profile/photos/66234734/original/photo.jpg"
    },
    {
        name: "lukaakakraien",
        rating: 5,
        review: "Extremely good explaining skills, notices every \nsingle mistake and gives you best guide throught the process!",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/ffcb3a4cc024a142dc6bb503178c2734-1623686898879/ab002118-d71a-4493-ab00-b0e08881f6d2.jpg"
    },
    {
        name: "seruz12",
        rating: 5,
        review: "gigachad...",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/29d10e0a4173a836347ef876b0f36129-1595622417274/1c429516-8c19-478d-a1ff-e9b524ddbb95.png"
    },
    {
        name: "wrightsreptiles",
        rating: 5,
        review: "Great coach.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/16eb4c9ab55addc38a945f0745c39acc-934968121690728199076/JPEG_20230730_104317_5714295620526658220.jpg"
    },
    {
        name: "mathieulussier",
        rating: 5,
        review: "I dont know what more to say than this coach knows his topics. You can 100% learn more about the game from him.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/5ba4c2d5b224176c445a032a66a72558-1584229360658/2eb73e54-b92e-4cb4-b308-b77b98706441.jpg"
    },
    {
        name: "blackkat96",
        rating: 5,
        review: "This is my 4th time writing a review for Sagittarius and everytime has been perfect.… is that enough of a reason to book your sessions? \nIt should be but if not, then do it for the fantastic customer service, the incredible knowledge and personalised sessions. \n\nNot much more to be said really. \n100% recommended. ",
        avatar: null
    },
    {
        name: "danieltaylor682",
        rating: 5,
        review: "helped me a ton to improve my game a lot. great coach with amazing service and advice",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/a98b49b67b03418455503e7610fe8446-1527509301850/402f2eab-44e6-42e9-a960-87516a0f841c.jpg"
    },
    {
        name: "goziytv",
        rating: 5,
        review: "Man... what to say. I bought the \"Pro Improvement Program.\" While I am not a pro just yet, I can say that his service is very pro! After the first session with my son and I, we saw immediate improvement. The second session was even better. The guy always answers questions and his discord is insanely helpful. I recommend and plan to continue buying, make the same decision, you won't regret it.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/9639e973f92794e5d1af6b2812103967-1533927592964/WeaponLogo.png"
    },
    {
        name: "remmino",
        rating: 5,
        review: "Ima just say he’s legit the best coach on fiverr cuz he tells you actually how to improve and after a one session with him you will understand much better what’s going on around the map… Trust him ",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/33cde466418b877e022f2c496f50ca30-937635741702297465.5733352/2516C5EE-5C8B-4B55-92A6-340938878D83"
    },
    {
        name: "lifecoachanass",
        rating: 5,
        review: "Coach is really well versed in his role and can 100% help you in whatever your issue is, he will break it down into simple steps that will directly help you improve. 100% consider hiring coach if you want to improve your games. ",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/88b144fde1e8f695cd680411aaf8579d-1680350154544/a6b3e512-38f0-4593-bba6-eeff70871bac.png"
    },
    {
        name: "chungagun",
        rating: 5,
        review: "un genio como siempre, muy recomendable!",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/284de21034c9f6a69a7c3ab7bed225ed-1672705523312/31e5da2f-1034-49bb-9426-6e00ff77ff9b.jpg"
    },
    {
        name: "kylekay286",
        rating: 5,
        review: "Great coaching, pointed out what I should work on, fundamentals to improve on and a spreadsheet with great information on how to improve and tools to learn. \nVery professional and helpful! Well worth the money and already feel more confident in improving. The added documents and homework helps give me structure and that is something I need in order to improve at anything I do. If you are in need of a coach I would recommend Sagittarius 100%!",
        avatar: null
    },
    {
        name: "nikkf9",
        rating: 5,
        review: "What an awesome experience! Can’t wait to schedule more",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/852406dfffa711596893528dce89417e-932185351597749246.6594439/684EF150-CF97-4E66-8D7F-A7CAB2F2ED33"
    },
    {
        name: "chungagun",
        rating: 5,
        review: "Un genio, muchisima paciencia tuvo, y muy bueno explicando, se merece 1000 estrelas.<3",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/284de21034c9f6a69a7c3ab7bed225ed-1672705523312/31e5da2f-1034-49bb-9426-6e00ff77ff9b.jpg"
    },
    {
        name: "jonasnordborger",
        rating: 5,
        review: "in general a really good coach easy to contact and learn you a lot in a short period of time",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/37a83bb9d25b281eea7dc80757f648ad-1669403567106/973fb3a6-9c57-46b3-a3fc-6a12af5b5908.jpg"
    },
    {
        name: "lionlikesprints",
        rating: 5,
        review: "Incredible coaching session. Highly recommend. \nThe coaching is structured very well and packed with high level content.\nSagittarius quickly and accurately analyzes your playstyle and common mistakes He provides easy to implement solutions. \nAll my questions are answered. I am looking forward to implementing what I've learned and get in touch with Sagittarius anytime I need professional coaching again.",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/3e4ecfff1f32b11abefac58f1bd59dc4-1584041343291/8d1170a6-c4f8-4a16-8f6d-b68fa66fd700.JPG"
    },
    {
        name: "killerbaum",
        rating: 5,
        review: "Great Coaching, Sagi knows what he's talking about, I've had so much fun playing League again because winning 13 Times in a row just feels great. Thanks for your Service!",
        avatar: "https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/1bdb433e6a4ebf3433aeea9c8a64b73a-753015061631787301622/JPEG_20210916_121501_5117877640762837840.jpg"
    },
    {
        name: "owmyback",
        rating: 5,
        review: "Sagittarius is without a doubt the best League coach around. I have been playing LoL for about 13 years off and on. I have received coaching from about 8 different challenger coaches and none of them have been as good as him. I am also already a pretty high level player. I am masters and have played in competitive leagues for many seasons now, yet every session with Sagittarius I learn so much. He really gets into the tiniest details of your gameplay and finds ways for you to improve from the very first moment you start working together. I could not possibly recommend a coach more than I recommend him! 100/100!",
        avatar: null
    }
  ];

  // State to manage the number of visible reviews
  const [visibleReviews, setVisibleReviews] = useState(9);

  // State to manage the modal
  const [modalReview, setModalReview] = useState(null);

  // Function to load more reviews
  const loadMoreReviews = () => {
    setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 6);
  };

  return (
    <div className="text-gray-200 pt-8 bg-white" id="reviews">
      <div className="w-full py-16">
        <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
          <div className="mb-10 space-y-4 px-6 md:px-0">
            <h2 className="text-center text-black text-2xl font-bold md:text-4xl">
              Customer reviews of our coaches and platform :)
            </h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {allReviews.slice(0, visibleReviews).map((review, index) => {
              const maxLength = 270; // Adjust the max length as needed
              const isLongReview = review.review.length > maxLength;
              const shortReview =
                isLongReview
                  ? review.review.substring(0, maxLength) + "..."
                  : review.review;

              return (
                <div
                  key={index}
                  className="md:h-[320px] p-8 border rounded-3xl bg-white shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out"
                >
                  <div className="flex gap-4 items-center">
                    <img
                      className="w-12 h-12 rounded-full"
                      src={review.avatar || defaultAvatar}
                      alt="user avatar"
                      loading="lazy"
                    />
                    <div>
                      <h6 className="text-lg font-medium text-black">
                        {review.name}
                      </h6>
                      {starRating(review.rating)}
                    </div>
                  </div>
                  <p className="mt-4 text-black">
                    {shortReview}
                    {isLongReview && (
                      <span
                        onClick={() => setModalReview(review)}
                        className="text-blue-500 cursor-pointer ml-2"
                      >
                        Read More
                      </span>
                    )}
                  </p>
                </div>
              );
            })}
          </div>

          {visibleReviews < allReviews.length && (
            <div className="flex justify-center mt-6">
              <button
                className="bg-[#00df9a] hover:bg-[#00bf8f] text-black font-bold py-2 px-4 rounded"
                onClick={loadMoreReviews}
              >
                Load More Reviews (really)
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      {modalReview && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={() => setModalReview(null)}
        >
          <div
            className="bg-white rounded-lg p-6 w-11/12 md:w-1/2 lg:w-1/3"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-black">
                {modalReview.name}'s Review
              </h3>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => setModalReview(null)}
              >
                &#x2715;
              </button>
            </div>
            <div className="text-black">
              <p>{modalReview.review}</p>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-[#00df9a] hover:bg-[#00bf8f] text-black font-bold py-2 px-4 rounded"
                onClick={() => setModalReview(null)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Review;
