import React from "react";
import awardIcon from "../assets/award.svg";
import trophyIcon from "../assets/trophy.svg";
import CoachProfile from "../components/CoachProfile"; // Create this component
import Testimonial from "../components/Testimonial";   // Create this component
import coach1 from "../assets/coach1.png";

const TrustSection = () => {
  return (
    <div
      id="about"
      className="w-full relative overflow-hidden py-16 bg-white"
    >
      <div className="absolute inset-0 bg-gray-100 opacity-50"></div>
      <div className="relative max-w-6xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-black mb-12">
          Why Choose Us for Your Coaching Journey?
        </h2>
        <p className="text-xl text-center text-gray-700 mb-16">
          We're a team of Apex rank coaches with over 3200 sessions coached, helping hundreds of players climb and improve their gameplay.
        </p>
        <div className="flex flex-wrap justify-center items-start space-y-8 md:space-y-0 md:space-x-8">
          <CoachProfile
            name="Coach Sagi"
            rank="Challenger Coach"
            experience="3100 coached session"
            imageSrc={coach1}
          />
          <CoachProfile
            name="Coach Kyan"
            rank="Grandmaster Midlane Coach"
            experience="10+ Years of Experience"
            imageSrc="https://fiverr-res.cloudinary.com/image/upload/t_profile_original,q_auto,f_auto/v1/attachments/profile/photo/6dca34bb5083644f7c2c5b0defdf0fb8-1645446560984/66ffa207-4459-42a9-87a9-21ad9cfa0a26.png"
          />
          {/* Add more CoachProfile components as needed */}
        </div>
        <div className="mt-16 flex justify-center items-center space-x-8">
          <img src={awardIcon} alt="Award" className="w-16 h-16" />
          <img src={trophyIcon} alt="Trophy" className="w-16 h-16" />
          {/* Add more social proof icons as needed */}
        </div>
      </div>
    </div>
  );
};

export default TrustSection;
