// Navbar.jsx
import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Logo from "./Logo";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const closeNav = () => {
    setNav(false);
  };

  return (
    <div className="w-full z-50 flex justify-between items-center h-24 px-4 bg-[#000300] text-white">
      <Logo />
      <ul className="hidden md:flex">
        <li className="p-4">
          <a href="/">Home</a>
        </li>
        <li className="p-4 capitalize">
          <a href="https://discord.gg/WCk7U5CBb9">Join our discord</a>
        </li>
      </ul>
      <div onClick={handleNav} className="block md:hidden">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Menu */}
      <div
        className={
          nav
            ? `fixed left-0 top-0 pt-4 w-[60%] h-screen border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500`
            : "fixed left-[-100%] top-0 pt-4 h-screen ease-in-out duration-500"
        }
      >
        <Logo />

        <ul className="p-4 uppercase">
          <li onClick={closeNav} className="p-4 border-b border-gray-600">
            <a href="/">Home</a>
          </li>
          <li onClick={closeNav} className="p-4">
            <a href="https://discord.gg/WCk7U5CBb9">Join our discord</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
