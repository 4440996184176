import React from "react";
import lectureImg from "../assets/lecture.png";
import vodreview from "../assets/vodreview.png";
import OneOnOne from "../assets/1on1.png";

const WhatWeDo = () => {
  return (
    <div className="w-full py-16 px-6 bg-black text-white" id="what-we-do">
      <div className="mb-16 text-center">
        <h2 className="text-4xl md:text-6xl font-bold uppercase">
          How We Can Help You Improve Your Game
        </h2>
      </div>
      <div className="max-w-8xl mx-auto space-y-24">
        {/* Bootcamp Program */}
        <div className="flex flex-col md:flex-row items-center md:space-x-24">
          <div className="md:w-1/2">
            <div className="p-6 md:p-8  rounded-lg">
              <h3 className="text-3xl md:text-5xl font-bold mb-6">
                Bootcamp Program
              </h3>
              <p className="text-lg md:text-2xl mb-6">
                A 4-week intensive bootcamp that includes weekly coaching
                sessions, VOD reviews, and more on our Discord.
              </p>
              <ul className="list-disc list-inside md:text-xl mb-6 space-y-4">
                <li><strong>Weekly, 1-hour coaching sessions with Q&A, where we teach fundamentals.</strong></li>
                <li><strong>Group VOD reviews where we help you apply what we teach.</strong></li>
                <li>All the recordings of Lectures and VOD reviews</li>
                <li>Training events</li>
                <li>op.gg reviews</li>
                <li>Access to a paid part of our Discord community.</li>
                <li>10% off on 1-on-1 sessions</li>
              </ul>
              <a
                href="/bootcamp-purchase"
                className="inline-block bg-[#00df9a] text-black rounded-md font-medium py-3 px-6 text-center mt-6"
              >
                Join Our Next Bootcamp
              </a>
            </div>
          </div>
          <div className="md:w-1/2 mt-12 md:mt-0">
            <img
              src={vodreview}
              alt="Bootcamp Program"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
        {/* 1-on-1 Coaching */}
        <div className="flex flex-col md:flex-row items-center md:space-x-16">
          <div className="md:w-1/2 order-2 md:order-1 mt-12 md:mt-0">
            <img
              src={OneOnOne}
              alt="1-on-1 Coaching"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
          <div className="md:w-1/2 order-1 md:order-2">
            <div className="p-6 md:p-8  rounded-lg">
              <h3 className="text-3xl md:text-5xl font-bold mb-6">
                1-on-1 Coaching
              </h3>
              <p className="text-lg md:text-xl  mb-6">
                Apart from the Bootcamp, we offer additional 1-on-1 coaching
                tailored to your gameplay style and goals.
              </p>
              <ul className="list-disc list-inside mb-6 md:text-xl space-y-4">
                <li>Live coaching or VOD review</li>
                <li>In-depth analysis and feedback.</li>
                <li>Homework to take away after the game</li>
              </ul>
              <a
                href="#bootcamp"
                className="inline-block bg-[#00df9a] text-black rounded-md font-medium py-3 px-6 text-center mt-6"
              >
                Book a Free Consultation Session
              </a>
            </div>
          </div>
        </div>
        {/* Free Community Events */}
        <div className="flex flex-col md:flex-row items-center md:space-x-16">
          <div className="md:w-1/2">
            <div className="p-6 md:p-8  rounded-lg">
              <h3 className="text-3xl md:text-5xl font-bold mb-6">
                Free Community Events
              </h3>
              <p className="text-lg md:text-xl mb-6">
                Join monthly free events hosted on our Discord and experience
                coaching firsthand.
              </p>
              <ul className="list-disc list-inside mb-6 md:text-xl space-y-4">
                <li>Participate in free live lectures and other events.</li>
                <li>Get a taste of our coaching approach.</li>
                <li>Chat in our community and receive perks!</li>
              </ul>
              <a
                href="https://discord.gg/WCk7U5CBb9"
                className="inline-block bg-[#00df9a] text-black rounded-md font-medium py-3 px-6 text-center mt-6"
              >
                Join Discord for Free
              </a>
            </div>
          </div>
          <div className="md:w-1/2 mt-12 md:mt-0">
            <img
              src={lectureImg}
              alt="Free Community Events"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
