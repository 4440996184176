import React from "react";
import { FaYoutube, FaDiscord } from "react-icons/fa";
import Logo from "../assets/logo.svg";

const Footer = () => {
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 gap-8 text-gray-300">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="flex self-center items-center">
          <img className="w-14" src={Logo} alt="" />
          <h1 className="w-full text-xl font-bold text-[#00df9a] uppercase">
            LeagueMentor
          </h1>
        </div>

        <div className="flex gap-8 justify-center my-6">
          <a href="https://www.youtube.com/channel/UCOMiBLiEyGf7Vpu5dulw9Pg" target="_blank" rel="noopener noreferrer">
            <FaYoutube size={30} />
          </a>
          <a href="https://discord.gg/WCk7U5CBb9" target="_blank" rel="noopener noreferrer">
            <FaDiscord size={30} />
          </a>
        </div>

        <div className="flex flex-col text-center">
          <p className="py-4">Copyright © 2025. All rights reserved.</p>
          <div className="flex gap-4 justify-center">
            <a
              href="/terms-and-conditions"
              className="text-[#00df9a] hover:underline"
            >
              Terms and Conditions
            </a>
            <a
              href="/privacy-policy"
              className="text-[#00df9a] hover:underline"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
