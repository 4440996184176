import React, { useState } from "react";

const LeadGeneration = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    rank: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  return (
    <div className="w-full py-16 px-6 bg-gray-900 text-white" id="bootcamp">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center">
        {/* Free Consultation Form */}
        <div className="md:w-1/2 md:pr-8">
          <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center md:text-left">
            Leave us a question or Book a Free Consultation!
          </h2>
          <form accept-charset='UTF-8' action='https://app.formester.com/forms/b5ff47ae-3438-4475-a207-8caf415666f3/submissions' method='POST' className="space-y-6">
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              className="w-full p-4 bg-gray-800 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-[#00df9a]"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              className="w-full p-4 bg-gray-800 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-[#00df9a]"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <textarea
              name="rank"
              placeholder="We will contact you right away, and answer any questions you have! You can also leave your discord here and we will contact you there!"
              className="w-full p-4 bg-gray-800 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-[#00df9a]"
              rows="5"
              value={formData.rank}
              onChange={handleChange}
              required
            ></textarea>
            <button
              type="submit"
              className="w-full bg-[#00df9a] text-black font-medium py-3 px-8 rounded-md hover:bg-green-400 transition-colors"
            >
              Submit & Get Started
            </button>
          </form>
        </div>

        {/* Divider with "OR" */}
        <div className="hidden md:flex flex-col items-center mx-8">
          <div className="w-px h-64 bg-gray-600 relative">
            <div className="absolute top-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-gray-900 px-2 text-sg font-bold">
              or
            </div>
          </div>
        </div>

        {/* Purchase Bootcamp Option */}
        <div className="md:w-1/2 md:pl-8 mt-12 md:mt-0">
          <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center md:text-left">
            Ready to Jump In?
          </h2>
          <p className="text-lg md:text-xl mb-8">
            Purchase our Bootcamp now and attend the next bootcamp! <strong>there's a limited amount of spots!</strong>
          </p>
          <a
            href="/bootcamp-purchase" // Update this link to your actual purchase page
            className="inline-block w-full bg-[#00df9a] text-black font-medium py-4 px-8 rounded-md hover:bg-green-400 transition-colors text-center text-xl"
          >
            Purchase Bootcamp
          </a>
          {/* Optional: Add more information or an image */}
          <div className="mt-2">
            <p className="md:text-xm">
              More info & FAQ available on the next page
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadGeneration;
