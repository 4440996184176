import React from "react";

const CoachProfile = ({ name, rank, experience, imageSrc }) => {
  return (
    <div className="w-full md:w-1/3 px-4 text-center">
      <img
        src={imageSrc}
        alt={name}
        className="w-32 h-32 mx-auto rounded-full mb-4"
      />
      <h4 className="text-2xl font-bold mb-2">{name}</h4>
      <p className="text-xl text-gray-700">{rank}</p>
      <p className="text-gray-600">{experience}</p>
    </div>
  );
};

export default CoachProfile;
