import React, { useEffect, useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import lectureImg from "../assets/lecture.png";
import vodreview from "../assets/vodreview.png";
import OneOnOne from "../assets/1on1.png";
import DiscordServer from "../assets/DiscordServer.png";
import LMlogo from "../assets/logo.svg";

const AdLandingPage = () => {
  const [eventTime, setEventTime] = useState('');

  useEffect(() => {
    // Set the document title for the AdLandingPage
    document.title = "Free Discord Coaching Lecture - Join Now";

    // Event date and time in CEST timezone
    const eventDate = new Date('2023-10-18T22:00:00+02:00'); // 10 PM CEST on 18th October 2023

    // Format the date to the user's local timezone
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    };
    const localEventTime = eventDate.toLocaleString(undefined, options);

    setEventTime(localEventTime);
  }, []); // Empty dependency array means this effect runs once when the component is mounted.

  const steps = [
    {
      title: 'Join our Discord server',
      description: (
        <>
        <p>We are holding a free lecture (1 to many coaching) for anyone that want's to get better! this month's topic is <strong>Jungle Tracking</strong></p>
          <a
            href="https://discord.gg/fnqWGwakR9"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            Click here to join the discord
          </a>
        </>
      ),
      image: vodreview, // Placeholder image URL
    },
    {
      title: 'Click "Interested" on the event',
      description:
        'Once you\'re in the Discord server, navigate to the events tab in top-right and click "Interested" on the bootcamp event.',
      image: DiscordServer, // Placeholder image URL
    },
    {
      title: 'Join the voice stage at the event time',
      description: (
        <>
          On <strong>{eventTime}</strong>, join the <em>Voice Stage</em> channel to participate.
        </>
      ),
      image: lectureImg, // Placeholder image URL
    },
  ];

  return (
    <div className="w-full py-16 px-6 bg-gray-100">
      {/* Landing Page Content */}
      <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-8">
          <h1 className="text-4xl md:text-5xl font-bold text-center mb-6">
            Join Our Free League Coaching Lecture!
          </h1>
          <p className="text-lg text-center text-gray-700 mb-8">
            Get a taste of our premium bootcamp for <strong>free</strong> and improve along the way!
          </p>

          <h2 className="text-3xl font-semibold mb-8 text-center">
            How to Join:
          </h2>

          <ol className="space-y-16 mb-12">
            {steps.map((step, index) => (
              <li key={index}>
                <div className="flex flex-col md:flex-row items-center md:items-start">
                  <img
                    src={step.image}
                    alt={`Step ${index + 1}`}
                    className="w-full md:w-1/2 h-auto mb-4 md:mb-0 md:mr-8 rounded-lg shadow-md"
                  />
                  <div className="md:flex-1">
                    <h3 className="text-2xl font-semibold mb-4">{step.title}</h3>
                    <p className="text-gray-700 text-lg">{step.description}</p>
                  </div>
                </div>
              </li>
            ))}
          </ol>

          <p className="text-center text-sm text-gray-600 italic">
            The event time has been adjusted to your local timezone.
          </p>

          {/* Call to Action Button */}
          <div className="mt-8 text-center">
            <a
              href="https://discord.gg/your-discord-invite-link"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-[#00df9a] text-black font-medium py-3 px-8 rounded-md transition-colors hover:bg-green-400"
            >
              Join Our Discord Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdLandingPage;
