// CheckoutPage.js

import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/solid";

const CheckoutPage = () => {
  // Extract the 'plan' parameter from the URL
  const query = new URLSearchParams(useLocation().search);
  const plan = query.get("plan") || "basic"; // Default to 'basic' plan if no parameter

  // Map of plan IDs corresponding to PayPal plan IDs
  const planIds = {
    basic: "P-6BX912169U577771JM34EFSI",    // Replace with your PayPal plan ID for Basic Plan
    standard: "P-1XH095050U321461LM364DXY", // Replace with your PayPal plan ID for Standard Plan
    premium: "P-72P77054NT884721YM364EGI",  // Replace with your PayPal plan ID for Premium Plan
  };

  // Plan details based on selected plan
  const planDetails = {
    basic: {
      name: "Basic Plan",
      price: "€15.50",
      features: [
        "1 month access to the Premium Part of the Discord",
        "Access to 1h+ long Weekly Lectures",
        "Recordings of all past Lectures",
        "100% full refund after the first month if you're not satisfied",
      ],
    },
    standard: {
      name: "Standard Plan",
      price: "€19.95",
      features: [
        "1 month access to the Premium Part of the Discord",
        "Access to 1h+ long Weekly Lectures",
        "Recordings of all past Lectures",
        "Weekly Group VOD reviews",
        "Community-based Training events",
        "10% off on 1-on-1 sessions",
        "100% full refund after the first month if you're not satisfied",
      ],
    },
    premium: {
      name: "Premium Plan",
      price: "€39.50",
      features: [
        "1 month access to the Premium Part of the Discord",
        "Access to 1h+ long Weekly Lectures",
        "Recordings of all past Lectures",
        "Weekly Group VOD reviews",
        "Community-based Training events",
        "10% off on 1-on-1 sessions",
        "One monthly free 1-on-1 session",
        "Coach Support Priority",
        "Monthly op.gg reviews",
        "100% full refund after the first month if you're not satisfied",
      ],
    },
  };

  const selectedPlan = planDetails[plan.toLowerCase()] || planDetails["basic"];
  const planId = planIds[plan.toLowerCase()] || planIds["basic"];

  const paypalContainerRef = useRef(null);

  useEffect(() => {
    const paypalScriptId = "paypal-sdk";
    let isMounted = true;
    let interval;

    const renderPayPalButton = () => {
      if (paypalContainerRef.current && window.paypal && isMounted) {
        // Clear any existing content
        paypalContainerRef.current.innerHTML = "";

        window.paypal
          .Buttons({
            style: {
              shape: "rect",
              color: "gold",
              layout: "vertical",
              label: "subscribe",
            },
            createSubscription: function (data, actions) {
              return actions.subscription
                .create({
                  plan_id: planId, // Use the dynamic plan ID
                })
                .catch((err) => {
                  console.error("Subscription creation failed:", err);
                  alert(
                    "An error occurred during subscription creation. Please try again later."
                  );
                });
            },
            onApprove: function (data, actions) {
              // Redirect to a success page or display a success message
              window.location.href = "/conversion";
            },
            onError: function (err) {
              console.error("PayPal Buttons error:", err);
              alert(
                "An error occurred with PayPal Buttons. Please try again later."
              );
            },
          })
          .render(paypalContainerRef.current)
          .catch((err) => {
            console.error("PayPal Button rendering failed:", err);
            alert(
              "An error occurred while rendering PayPal Buttons. Please try again later."
            );
          });
      } else {
        console.error("PayPal SDK not loaded yet or component unmounted.");
      }
    };

    const addPayPalScript = () => {
      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=AfmBagSVRHsRBeq_FeHlEzWgcn1GVopybCvOYavnmTgTZh0dHsI4UCvOXNFDqEyqw2OwGxm-OmiYFqz4&vault=true&intent=subscription&currency=EUR";
      script.id = paypalScriptId;
      script.onload = () => {
        if (isMounted) {
          renderPayPalButton();
        }
      };
      document.body.appendChild(script);
    };

    if (typeof document !== "undefined" && document.body) {
      if (!document.getElementById(paypalScriptId)) {
        addPayPalScript();
      } else {
        // Wait for the SDK to be ready before rendering the button
        if (window.paypal) {
          renderPayPalButton();
        } else {
          interval = setInterval(() => {
            if (window.paypal && isMounted) {
              clearInterval(interval);
              renderPayPalButton();
            }
          }, 50);
        }
      }
    }

    // Cleanup function
    return () => {
      isMounted = false;
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [planId]);

  return (
    <div className="w-full min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4">
      <div className="max-w-3xl w-full bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="md:flex">
          {/* Plan Details Section */}
          <div className="md:w-1/2 p-8">
            <h2 className="text-3xl font-bold mb-4">{selectedPlan.name}</h2>
            <p className="text-2xl font-semibold mb-4">
              {selectedPlan.price} / month
            </p>
            <p className="text-sm text-gray-500 mb-6">+ VAT</p>
            <h3 className="text-xl font-semibold mb-3">What you'll get:</h3>
            <ul className="space-y-4 mb-6">
              {selectedPlan.features.map((feature, index) => (
                <li key={index} className="flex items-start">
                  <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                  <span>
                    {feature.includes("100% full refund") ? (
                      <strong>{feature}</strong>
                    ) : (
                      feature
                    )}
                  </span>
                </li>
              ))}
            </ul>
            <p className="text-sm text-gray-700">
              All prices are exclusive of VAT. VAT will be calculated at
              checkout based on your location.
            </p>
            <p className="text-sm text-gray-700 mt-2">
              We offer a <strong>100% full refund</strong> after the first month
              if you're not satisfied with the bootcamp.
            </p>
          </div>
          {/* Payment Section */}
          <div className="md:w-1/2 bg-gray-50 p-8 flex flex-col justify-center">
            <h3 className="text-2xl font-semibold mb-6 text-center">
              Complete Your Purchase
            </h3>
            {/* PayPal Button Container with Unique ID */}
            <div
              id={`paypal-button-container-${planId}`}
              ref={paypalContainerRef}
              className="mb-6"
            ></div>
            <p className="text-center text-sm text-gray-600">
              You will get a PayPal payment pop-up to complete your purchase
              securely.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
